<div
  itemprop="offers"
  itemscope
  itemtype="http://schema.org/Offer"
  class="product-price"
  *ngIf="price?.sale"
>
  <h2 itemprop="price" class="text-theme-on-surface" *ngIf="price">
    <span [ngClass]="{ 'old-price': price.sale }">
      {{ price!.baseValue | currency : price!.currency }}
    </span>
    <span class="sale-price" *ngIf="price.sale ?? false">
      {{ price.sale | currency : price.currency }}
    </span>
  </h2>
</div>
<div
  itemprop="offers"
  itemscope
  itemtype="http://schema.org/Offer"
  class="product-price"
  *ngIf="
 !price?.sale && priceRange && (
    priceRange.min == 0 ||
    priceRange.max == 0 ||
    (priceRange.min == priceRange.max )|| !priceRange
  )
  "
>
  <h2 class="text-theme-on-surface" *ngIf="price ?? true">
    {{ priceRange?.max ?? 0 | currency : currency }}
  </h2>
</div>
<div class="product-price">
  <h2
    class="text-theme-on-surface"
    *ngIf="
      priceRange.min != 0 &&
      priceRange.max != 0 &&
      priceRange.min != priceRange.max &&
      !price!.sale
    "
  >
    {{ priceRange.min | currency : currency }}
    -
    {{ priceRange.max | currency : currency }}
  </h2>
</div>
@if(isChanged)
{

    <button class="payment-installment button"(click)="showClearPay()" >
      @if(price!.sale?? false){
      <span class="installment-text">
        {{ 4 }} interest-free payments of £{{ price!.sale / 4 }} with 

      </span>}
      @else{
        <span class="installment-text">
        {{ 4 }} interest-free payments of £{{ priceRange.max / 4 }} with 

      </span>}
      <span >
        <img alt="clearpay" class="p-Logo p-AsyncLogo p-Logo--light p-AsyncLogo--loaded p-PartnerLogoImg p-PartnerLogoImg--clearpay" 
        src="https://js.stripe.com/v3/fingerprinted/img/ClearpayFullLogo-fa10f802cb79c33f8ab00cd6d5a8be8e.svg">
      </span>
    
      <span class="info-icon " (mouseenter)="showInfo = true" (mouseleave)="showInfo = false" >
        <i class="fas fa-info-circle"></i>
      </span>
    </button>
    
  @if(showClearPayment){
<app-bnpl-info [purchasePrice]="priceRange.max/4" [installmentPrice]="priceRange.max" class="m-1">
  
</app-bnpl-info>
  } 
}