<div class="ea-bnpl">
    <div class="bnpl-header-section d-flex align-items-center">
        <div class="bnpl-header">
            Buy Now. Pay Later.
        </div>      
    </div>
    <div class="bnpl-sub-header">
        Purchase price: {{currencySymbol}}{{purchasePrice}}
    </div>
    <div class="bnpl-purchase-flow">
        <div class="bnpl-purchase-flow-header">
            Select Clearpay as your payment method at checkout to pay in installments.
        </div>
        <div class="bnpl-purchase-flow-diagram">
            <div class="clearpay-timeline">
                <div class="circle"></div>
                <div class="dashed"></div>
                <div class="circle"></div>
                <div class="dashed"></div>
                <div class="circle"></div>
                <div class="dashed"></div>
                <div class="circle"></div>
              </div>
        </div>
        <div class="bnpl-purchase-flow-steps d-flex">
            <div class="bnpl-step1">Add items to your cart</div>
            <div class="bnpl-step2">Select Clearpay at checkout</div>
            <div class="bnpl-step3">Receive an approval decision</div>
            <div class="bnpl-step4">If approved, pay in installments</div>
        </div>
    </div>
    <div class="bnpl-payment-summary d-flex">
        <div class="clear-pay-installments">
            <div class="installment-plan">4 payments of {{ currencySymbol }}{{ installmentPrice }}</div>
            <div class="installment-frequency">every 2 weeks, interest-free</div>
            <div class="installments-total">Total: {{ currencySymbol }}{{ purchasePrice }}</div>
        </div>
        <div class="clearpay-logo-terms">
            <div class="clearpay-logo-div">
                <img alt="clearpay-logo" class="clearpay-logo" src="assets/images/clearpay-logo-blackmint.png" />
            </div>

            <div class="clearpay-terms">
                <span>See terms: </span> <a class="" href="https://www.clearpay.co.uk/en-GB/terms-of-service">Clearpay</a>
            </div>
        </div>
    </div>
    <div class="bnpl-footer">
        Clearpay: Clearpay lends you a fixed amount of credit so you can pay for your purchase over 4 installments, due every 2 weeks. Ensure you can make repayments on time. You must be 18+ and a permanent UK resident (excl Channel Islands). Clearpay charges a £6 late fee for each late installment and a further £6 if it's still unpaid 7 days later. Late fees are capped at £6 for orders under £24 and the lower of £24 or 25% of the order value for orders over £24. Missed payments may affect your credit history....
    </div>
</div>
