import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-bnpl-info',
  templateUrl: './bnpl-info.component.html',
  styleUrl: './bnpl-info.component.scss',
  standalone: true
})
export class BnplInfoComponent {
  @Input() currencySymbol: string = '£';
  @Input() purchasePrice: number = 0;
  @Input() installmentPrice: number = 0;
}
